import React, { useEffect, useState } from "react";
import {
  useToast,
  useTheme,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Switch } from "@chakra-ui/switch";
import { get, omit } from "lodash";
import { navigate } from "gatsby";
import moment from "moment";
import { Field, Form as FinalForm } from "react-final-form";
import { stringify } from "query-string";
import { useLocation } from "@reach/router";

import { get as GET, post, put } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Heading from "../../base/Heading";
import Button from "../../base/Button";
import Spinner from "../../base/Spinner";
import Table from "../../Table";
import Icon from "../../Icon";
import Dropdown from "../../base/Dropdown";
import {
  ALL_FLEET,
  STATUS,
  TOTAL_JUMP_PAGES,
} from "../../../utilities/constants";
import search from "../../../utilities/search";
import dashboardConstants from "../../../utilities/dashboard-constants";
import generateSearchString from "../../../utilities/generate-search-string";
import responsiveCss from "../../../utilities/responsiveCss";
import removeTrailingSlashes from "../../../utilities/removeTrailingSlashes";
import DashboardPaginator from "../../CommonComponents/DashboardPaginator";
import Modal from "../../base/Modal";

const UnitListingPage = () => {
  const theme = useTheme();
  const toast = useToast();
  const location = useLocation();
  const [unit, setUnit] = useState(null);
  const [unitData, setUnitData] = useState({});
  const [sample, setSample] = useState(true);
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchValue, setSearchValue] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const sampleToggle = () => {
    setSample(!sample);
  };
  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };
  const { filters, pageCriteria } = dashboardConstants.getCriteria({
    search: location.search,
    type: "units",
  });
  const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  );

  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const cityDropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...cityDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-system?${generateSearchString(query)}`);
      const systemDropDownData = get(data, "data", []).map((system) => ({
        label: `#${get(system, "system_id")} - ${get(system, "system")}`,
        value: get(system, "system_id"),
      }));
      setSystemDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...systemDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(
        `/listing-subsystem?${generateSearchString(query)}`
      );
      const subsystemDropDownData = get(data, "data", []).map((subsystem) => ({
        label: `#${get(subsystem, "subsystem_id")} - ${get(
          subsystem,
          "subsystem"
        )}`,
        value: get(subsystem, "subsystem_id"),
      }));
      setSubsystemDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...subsystemDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
      });
      const data = await GET(`/listing-line?${generateSearchString(query)}`);
      const lineDropDownData = get(data, "data", []).map((line) => ({
        label: `#${get(line, "line_id")} - ${get(line, "line")}`,
        value: get(line, "line_id"),
      }));
      setLineDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...lineDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [location]);
  useEffect(async () => {
    try {
      const query = dashboardConstants.getQuery({
        filters,
        page: pageCriteria,
      });
      const data = await GET(`/listing-unit?${generateSearchString(query)}`);
      if (data) {
        const filteredUnitData = searchValue
          ? search(get(data, "data", []), searchValue, "unit")
          : get(data, "data", []);

        setUnitData({ ...data, data: filteredUnitData });
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [sample, location]);

  const tableHeader = [
    { label: "#", value: "unitId" },
    { label: "Unidad", value: "unit" },
    { label: "¿Es Estación?", value: "isStation?" },
    { label: "Posición", value: "position" },
    { label: "Ciudad", value: "city" },
    { label: "Sistema", value: "system" },
    { label: "Sub-sistema", value: "subsystem" },
    { label: "Línea", value: "line" },
    { label: "Habilitado", value: "enabled" },
    { label: "Creación", value: "creationDate" },
    { label: "Acciones", value: "actions" },
  ];
  const pageTitle = "Listado de unidades";

  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  return (
    <Route isPrivate layout="admin">
      <Section sx={{ mx: "auto" }}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Heading {...theme.variant.heading}>{pageTitle}</Heading>
          <Button
            onClick={() => {
              navigate("/units/add-unit");
            }}
          >
            Añadir nueva unidad
          </Button>
        </Box>
        <Box>
          <FinalForm
            onSubmit={async () => {}}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, "row"],
                    mb: 8,
                  }}
                >
                  <Box
                    as="input"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      sampleToggle();
                    }}
                    placeholder="Search"
                    sx={{
                      ...theme.variant.input.primary,
                      mb: 4,
                      mr: 4,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    type="text"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="city_id"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(
                              filter,
                              ["city_id"],
                              ["system_id"],
                              ["subsystem_id"],
                              ["line_id"]
                            )
                          : omit(
                              { ...filter, city_id: value },
                              ["system_id"],
                              ["subsystem_id"],
                              ["line_id"]
                            );
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={cityDropDownOptions}
                    placeholder="Select City"
                    sx={{ maxWidth: "100%" }}
                    wrapperSx={{ ...colStyled }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="system_id"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(
                              filter,
                              ["system_id"],
                              ["subsystem_id"],
                              ["line_id"]
                            )
                          : omit(
                              { ...filter, system_id: value },
                              ["subsystem_id"],
                              ["line_id"]
                            );
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={systemDropDownOptions}
                    placeholder="Select System"
                    sx={{ maxWidth: "100%" }}
                    wrapperSx={{ ...colStyled }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="subsystem_id"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(filter, ["subsystem_id"], ["line_id"])
                          : omit({ ...filter, subsystem_id: value }, [
                              "line_id",
                            ]);
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={subsystemDropDownOptions}
                    placeholder="Select Subsystem"
                    sx={{ maxWidth: "100%" }}
                    wrapperSx={{ ...colStyled }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    name="line_id"
                    onChange={(value) => {
                      const filterObject =
                        value === ALL_FLEET
                          ? omit(filter, ["line_id"])
                          : { ...filter, line_id: value };
                      setFilter(filterObject);
                      const searchParams = stringify({
                        filters: stringify(filterObject),
                        page: stringify({
                          limit: 10,
                          offset: 0,
                        }),
                      });
                      navigate(
                        `${removeTrailingSlashes(location.pathname)}${
                          searchParams ? "?" : ""
                        }${searchParams}`,
                        {
                          replace: true,
                        }
                      );
                    }}
                    options={lineDropDownOptions}
                    placeholder="Select Line"
                    sx={{ maxWidth: "100%" }}
                    wrapperSx={{ ...colStyled }}
                  />
                </Box>
              </form>
            )}
          />
        </Box>
        <Box>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Table
                header={tableHeader}
                renderEmpty="Sin datos"
                rows={get(unitData, "data", []).map((unit) => [
                  get(unit, "unit_id", "-"),
                  get(unit, "unit", "-"),
                  <Box
                    color="mediumGreen"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {unit.is_station === STATUS.ACTIVE ? (
                      <Icon
                        svg="is-station"
                        sx={{ color: "mediumGreen", width: "18px" }}
                      />
                    ) : (
                      <Icon svg="not-station" sx={{ width: "18px" }} />
                    )}
                  </Box>,
                  <Box
                    as="a"
                    color="mediumGreen"
                    href={
                      unit.is_station === STATUS.ACTIVE &&
                      `https://maps.google.com/?z=15&q=${[
                        unit.lat,
                        unit.lon,
                      ].join(",")}`
                    }
                    sx={{ display: "flex", justifyContent: "center" }}
                    target="_blank"
                  >
                    {unit.is_station === STATUS.ACTIVE && (
                      <Icon svg="map-pin" sx={{ width: "24px" }} />
                    )}
                  </Box>,
                  get(unit, "city", "-"),
                  get(unit, "system", "-"),
                  get(unit, "subsystem", "-"),
                  get(unit, "line", "-"),
                  <Switch
                    key={`status-${unit.unit_id}`}
                    colorScheme="green"
                    defaultChecked={get(unit, "enabled") === STATUS.ACTIVE}
                    onChange={async () => {
                      try {
                        const payLoad = {
                          enabled:
                            get(unit, "enabled") === STATUS.ACTIVE
                              ? STATUS.INACTIVE
                              : STATUS.ACTIVE,
                          is_station: get(unit, "is_station"),
                          lat: get(unit, "lat"),
                          lon: get(unit, "lon"),
                          unit: get(unit, "unit"),
                          unit_id: get(unit, "unit_id"),
                        };
                        const data = await put("/update-unit", payLoad);
                        sampleToggle();
                        if (data) {
                          toastMessage({
                            message: "Unit Edited!",
                            toast,
                            type: "success",
                          });
                        }
                      } catch (e) {
                        toastMessage({
                          message: e.message,
                          toast,
                          type: "error",
                        });
                      }
                    }}
                    variant="primary"
                  />,
                  get(unit, "created_at")
                    ? moment(get(unit, "created_at")).format("LLLL")
                    : "-",
                  <Menu>
                    <MenuButton
                      aria-label="Options"
                      as={IconButton}
                      icon={
                        <Icon svg="more-horizontal" sx={{ width: "20px" }} />
                      }
                      sx={{
                        "&:hover": {
                          bg: "transparent",
                        },
                        ":active": {
                          bg: "transparent",
                        },
                        bg: "transparent",
                      }}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate(`/units/${get(unit, "unit_id")}`, {
                            state: { unit },
                          });
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setUnit(get(unit, "unit_id"));
                          deleteToggle();
                        }}
                        sx={{
                          "&:hover": {
                            bg: "lightGreen",
                          },
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>,
                ])}
                tableSx={{
                  borderCollapse: "collapse",
                  overflow: "visible",
                }}
              />
              {unitData.total > 0 && (
                <DashboardPaginator
                  buttonSx={{
                    color: "primary",
                  }}
                  enableJumpToPage
                  filters={filters}
                  pageCriteria={pageCriteria}
                  pageTotal={get(unitData, "data", []).length}
                  total={get(unitData, "total")}
                  totalJumpToPages={totalJumpToPages}
                />
              )}
            </>
          )}
        </Box>
        <Modal
          bodySx={{ pb: 0 }}
          footer={
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                onClick={async () => {
                  const data = await post("/delete-unit", { unit_id: unit });
                  toastMessage({
                    message:
                      get(data, "success") === STATUS.INACTIVE
                        ? get(data, "msm")
                        : "Unit deleted successfully!",
                    toast,
                    type:
                      get(data, "success") === STATUS.INACTIVE
                        ? "error"
                        : "success",
                  });
                  deleteToggle();
                  sampleToggle();
                }}
                sx={{ mr: 3 }}
              >
                Yes
              </Button>{" "}
              <Button
                onClick={() => {
                  deleteToggle();
                }}
                sx={{ ml: 3 }}
              >
                Cancel
              </Button>
            </Box>
          }
          onClose={deleteToggle}
          open={deleteModal}
        >
          <Box
            sx={{
              color: "mediumGreen",
              display: "flex",
              justifyContent: "center",
              my: 5,
            }}
          >
            Are you sure to delete this unit?
          </Box>
        </Modal>
      </Section>
    </Route>
  );
};
export default UnitListingPage;
