import React from "react";
import { Router } from "@reach/router";

import UnitListingPage from "../components/PageComponents/UnitListingPage";
import UnitAddPage from "../components/PageComponents/UnitAddPage";
import UnitUpdatePage from "../components/PageComponents/UnitUpdatePage";

const Units = (props) => (
  <Router>
    <UnitListingPage {...props} path="/units" />
    <UnitAddPage {...props} path="/units/add-unit" />
    <UnitUpdatePage {...props} path="/units/:unitId" />
  </Router>
);

export default Units;
