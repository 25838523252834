import React, { useEffect, useState } from "react";
import { useTheme, useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { navigate } from "gatsby";
import { Field, Form as FinalForm } from "react-final-form";
import toastMessage from "../../../utilities/toastMessage";
import Box from "../../base/Box";
import Input from "../../base/Input";
import { float, required } from "../../../validations";
import FormError from "../../base/FormError";
import Button from "../../base/Button";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Heading from "../../base/Heading";
import { get as GET, post, put } from "../../../utilities/http";
import Dropdown from "../../base/Dropdown";
import Switch from "../../base/Switch";
import { STATUS } from "../../../utilities/constants";

const AddUpdateUnitForm = ({ location }) => {
  const theme = useTheme();
  const toast = useToast();
  const gap = 7;
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [selectedSubsystem, setSelectedSubsystem] = useState(null);
  const unit = get(location, "state.unit", null);
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: "60%",
    mb: gap,
    mx: 4,
    width: "50%",
  };
  const colFull = {
    minWidth: "100%",
  };
  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const dropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  useEffect(async () => {
    try {
      const { data } = selectedCity
        ? await GET(`/listing-system?city_id=${selectedCity}`)
        : await GET("/listing-system");
      const dropDownData = data.map((system) => ({
        label: `#${get(system, "system_id")} - ${get(system, "system")}`,
        value: get(system, "system_id"),
      }));
      setSystemDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedCity]);
  useEffect(async () => {
    try {
      const { data } = selectedSystem
        ? await GET(`/listing-subsystem?system_id=${selectedSystem}`)
        : await GET("/listing-subsystem");
      const dropDownData = data.map((subsystem) => ({
        label: `#${get(subsystem, "subsystem_id")} - ${get(
          subsystem,
          "subsystem"
        )}`,
        value: get(subsystem, "subsystem_id"),
      }));
      setSubsystemDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedSystem]);
  useEffect(async () => {
    try {
      const { data } = selectedSubsystem
        ? await GET(`/listing-line?subsystem_id=${selectedSubsystem}`)
        : await GET("/listing-line");
      const dropDownData = data.map((line) => ({
        label: `#${get(line, "line_id")} - ${get(line, "line")}`,
        value: get(line, "line_id"),
      }));
      setLineDropDownOptions(dropDownData);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, [selectedSubsystem]);
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: ["90%", null, null, "70%"],
            mb: 4,
            mx: "auto",
          }}
        >
          <Heading {...theme.variant.heading}>
            {unit ? "Unidad de actualización" : "Añadir nuevo unidad"}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: "white",
            borderRadius: 4,
            maxWidth: ["90%", null, "70%"],
            mx: "auto",
            p: 10,
          }}
        >
          <FinalForm
            initialValues={{
              city: get(unit, "city_id"),
              enabled: get(unit, "enabled"),
              is_station: get(unit, "is_station") === STATUS.ACTIVE,
              lat: get(unit, "lat", ""),
              line: get(unit, "line_id"),
              lon: get(unit, "lon", ""),
              subsystem: get(unit, "subsystem_id"),
              system: get(unit, "system_id"),
              unit: get(unit, "unit"),
            }}
            onSubmit={async (values) => {
              try {
                const payload = unit
                  ? {
                      ...unit,
                      enabled: values.enabled ? STATUS.ACTIVE : STATUS.INACTIVE,
                      is_station: values.is_station
                        ? STATUS.ACTIVE
                        : STATUS.INACTIVE,
                      lat: values.lat,
                      lon: values.lon,
                      unit: values.unit,
                    }
                  : {
                      city_id: values.city,
                      enabled: values.enabled ? STATUS.ACTIVE : STATUS.INACTIVE,
                      is_station: values.is_station
                        ? STATUS.ACTIVE
                        : STATUS.INACTIVE,
                      lat: values.lat,
                      line_id: values.line,
                      lon: values.lon,
                      subsystem_id: values.subsystem,
                      system_id: values.system,
                      unit: values.unit,
                    };
                let data;
                let message;
                if (unit) {
                  data = await put("/update-unit", payload);
                  message = data && "Unit updated!";
                } else {
                  data = await post("/add-unit", payload);
                  message = data && "Unit Added!";
                }
                if (data) {
                  toastMessage({
                    message,
                    toast,
                    type: "success",
                  });
                }
                navigate("/units");
              } catch (e) {
                toastMessage({
                  message: e.message,
                  toast,
                  type: "error",
                });
              }
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="city"
                    onChange={(value) => {
                      setSelectedCity(value);
                    }}
                    options={cityDropDownOptions}
                    placeholder="Ciudad"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="system"
                    onChange={(value) => {
                      setSelectedSystem(value);
                    }}
                    options={systemDropDownOptions}
                    placeholder="Sistema"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="subsystem"
                    onChange={(value) => {
                      setSelectedSubsystem(value);
                    }}
                    options={subsystemDropDownOptions}
                    placeholder="Sub-sistema"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    as={Field}
                    component={Dropdown}
                    disabled={!!unit}
                    name="line"
                    options={lineDropDownOptions}
                    placeholder="Líneas"
                    sx={{
                      "::placeholder": {
                        color: "grays.3",
                        fontSize: 2,
                      },
                      width: "100%",
                    }}
                    validate={required}
                    wrapperSx={{ ...colStyled, fontSize: 2, mx: "auto" }}
                  />
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: "flex",
                      maxWidth: "60%",
                      width: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 2,
                        maxWidth: "60%",
                        mr: 3,
                        my: 3,
                        textTransform: "none",
                      }}
                    >
                      ¿Es estación?
                    </Box>
                    <Box
                      as={Field}
                      component={Switch}
                      label=""
                      name="is_station"
                      toggleSwitchSx={{ ml: 5, my: 4 }}
                      type="checkbox"
                    />
                  </Box>
                  <Box
                    as={Field}
                    component={Input}
                    disabled={
                      get(formContext, "values.is_station", false) === false
                    }
                    name="lat"
                    placeholder="Latitud"
                    validate={float(10)}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    disabled={
                      get(formContext, "values.is_station", false) === false
                    }
                    name="lon"
                    placeholder="Longitud"
                    validate={float(10)}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    as={Field}
                    component={Input}
                    name="unit"
                    placeholder="Nombre de la unidade"
                    validate={required}
                    width="100%"
                    wrapperSx={colStyled}
                  />
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: "flex",
                      maxWidth: "60%",
                      width: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 2,
                        maxWidth: "60%",
                        mr: 3,
                        my: 3,
                        textTransform: "none",
                      }}
                    >
                      ¿Habilitada?
                    </Box>
                    <Box
                      as={Field}
                      component={Switch}
                      label=""
                      name="enabled"
                      toggleSwitchSx={{ ml: 5, my: 4 }}
                      type="checkbox"
                    />
                  </Box>
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      ...colFull,
                    }}
                  >
                    <Button
                      disabled={formContext.pristine || formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ mr: 3, width: "130px" }}
                      type="submit"
                      variant="primary"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/units");
                      }}
                      sx={{ ml: 3, width: "130px" }}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
      </Section>
    </Route>
  );
};

export default AddUpdateUnitForm;
